import { useRouter } from 'next/router'
import { type FC, memo, useMemo } from 'react'
import styled from 'styled-components'

import UnderlineTabs from 'components/Button/UnderlineTabs'
import ROUTES from 'constants/routes'
import media from 'styles/media'

export const HistoryTabs = {
	POSITIONS: 'positions',
	TRADES: 'trades',
	ORDER_HISTORY: 'order_history',
	TRANSACTION_HISTORY: 'transaction_history',
} as const

export type HistoryTab = (typeof HistoryTabs)[keyof typeof HistoryTabs]

export const HistoryTabValues = Object.values(HistoryTabs)

type HistoryTabsProps = {
	activeTab: HistoryTab
}

const HistoryTabsMenu: FC<HistoryTabsProps> = memo(({ activeTab }) => {
	const router = useRouter()

	const historyTabs = useMemo(
		() => [
			{
				name: HistoryTabs.POSITIONS,
				label: 'Positions',
				active: activeTab === HistoryTabs.POSITIONS,
				onClick: () => router.push(ROUTES.Dashboard.History(HistoryTabs.POSITIONS)),
			},
			{
				name: HistoryTabs.TRADES,
				label: 'Trades',
				active: activeTab === HistoryTabs.TRADES,
				onClick: () => router.push(ROUTES.Dashboard.History(HistoryTabs.TRADES), undefined),
			},
			{
				name: HistoryTabs.ORDER_HISTORY,
				label: 'Orders History',
				active: activeTab === HistoryTabs.ORDER_HISTORY,
				onClick: () => router.push(ROUTES.Dashboard.History(HistoryTabs.ORDER_HISTORY), undefined),
			},
			{
				name: HistoryTabs.TRANSACTION_HISTORY,
				label: 'Transaction History',
				active: activeTab === HistoryTabs.TRANSACTION_HISTORY,
				onClick: () =>
					router.push(ROUTES.Dashboard.History(HistoryTabs.TRANSACTION_HISTORY), undefined),
			},
		],
		[activeTab, router]
	)
	return (
		<StyledUnderlineTabs
			options={historyTabs.filter((tab) => tab.name !== HistoryTabs.TRANSACTION_HISTORY)}
			noBorders
		/>
	)
})

const StyledUnderlineTabs = styled(UnderlineTabs)`
	height: 45px;
	width: 100%;
	& > div:first-child {
		padding-left: 0;
	}

	${media.lessThan('lg')`
		overflow-x: auto;
		white-space: nowrap;
	`}
`

export default HistoryTabsMenu
