import type { PerpsProvider } from '@kwenta/sdk/types'
import ROUTES from 'constants/routes'
import { useCallback, useMemo } from 'react'
import { selectPerpsProvider } from 'state/futures/common/selectors'
import { selectUsersPositions } from 'state/futures/selectors'
import { useAppSelector } from 'state/hooks'

export function useMarketPageRoute() {
	const userPositions = useAppSelector(selectUsersPositions)
	const selectedPerpsProvider = useAppSelector(selectPerpsProvider)

	const market = useMemo(() => {
		const openPositions = userPositions.filter((p) => p.details.status === 'open')
		if (!openPositions.length) return 'sETH'

		return openPositions[0].asset
	}, [userPositions])

	return useCallback(
		(perpsProvider?: PerpsProvider) =>
			ROUTES.Markets.Home(perpsProvider ?? selectedPerpsProvider, market),
		[market, selectedPerpsProvider]
	)
}
